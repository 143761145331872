import { DeviceInitDataModel } from '@/pages/050__deviceManager/deviceInitList/model/DeviceInitDataModel';

export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        // BaseTableColumn.initData('deviceCategoryName', '设备类别').setWidth(100).setSpan({}),
        // BaseTableColumn.initData('portCategoryName', '端口类别').setWidth(100).setSpan({}),
        BaseTableColumn.initData('deviceModel', '出厂信息', 160).setKeyValueList([
          { label: '端口类别:', labelWidth: 74, key: 'portCategoryName', attr: {} },
          { label: '设备商名称:', labelWidth: 74, key: 'deviceProviderName', attr: {} }
        ]),
        BaseTableColumn.initData('deviceModel', '设备型号', 150).setKeyValueList([
          { label: '型号编码:', labelWidth: 60, key: 'deviceModel', attr: { } },
          { label: '型号名称:', labelWidth: 60, key: 'deviceTypeName', attr: { } }
        ]),
        BaseTableColumn.initData('deviceNumber', '设备信息', 160).setKeyValueList([
          { label: '设备编码:', labelWidth: 60, key: 'deviceNumber', attr: { canTooltip: true } },
          { label: 'IMEI号:', labelWidth: 60, key: 'imei', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('iotInfo', '物联网').setSpan({}),
        BaseTableColumn.initData('time', '时间', 200).setKeyValueList([
          { label: '出厂上传:', labelWidth: 60, key: 'uploadTime', attr: { canTooltip: true } },
          { label: '设备安装:', labelWidth: 60, key: 'activeTime', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('time', '联网/安装\n状态').setWidth(130).setKeyValueList([
          {
            label: '历史联网:',
            labelWidth: 60,
            key: 'h5ConnectPlatform',
            attr: {
              className: (row) => { return !row.connectPlatform ? 'col-text-light' : ''; }
            }
          },
          {
            label: '安装状态:',
            labelWidth: 60,
            key: 'h5ActiveStatus',
            attr: {
              className: (row) => { return !row.activeStatus ? 'col-text-light' : ''; }
            }
          }
        ]),
        // BaseTableColumn.initData('uploadTime', '出厂\n上传时间').setWidth(150).setSpan({}),
        // BaseTableColumn.initData('deviceProviderName', '设备商名称').setSpan({}),
        // BaseTableColumn.initData('h5ConnectPlatform', '历史\n联网记录').setWidth(60),
        // BaseTableColumn.initData('h5ActiveStatus', '安装状态').setSpan({}),
        // BaseTableColumn.initData('h5IsOnlineDesc', '是否在线').setSpan({}),
        BaseTableColumn.initData('isOnline', '是否在线').setWidth(70).setRender((h, { row, index }) => {
          return h('yg-table-signal', {
            attrs: {
              showSignal: false,
              statusValue: row.isOnline,
              statusDesc: row.h5IsOnlineDesc,
              simIccid: row.iccid,
              simEE: row.h5SimCardEE
            }
          });
        }),
        // BaseTableColumn.initData('activeTime', '安装时间').setWidth(150).setSpan({}),
        BaseTableColumn.initData('propertyCompanyName', '安装所属\n物业公司名称', 140).setSpan({}),
        BaseTableColumn.initData('version', '软件\n版本号', 60).setSpan({}),
        BaseTableColumn.initData('setting', '操作').setWidth(160).setAlign('center').setButtons([
          { title: '调整软件版本', name: 'version', permission: 'system:device:adjustDeviceVersion' },
          { title: '修改IMEI号', name: 'IMEI', permission: 'system:device:factory:updateImei' }
        ], ({ name }, row, index) => {
          switch (name) {
          case 'version':
            this.dialog.versionModel = row;
            break;
          case 'IMEI':
            console.log(row.activeStatus);
            if (row.activeStatus === 1) {
              return this.$Message.warning('仅未安装状态才可修改IMEI号');
            }
            this.dialog.imeiModel = DeviceInitDataModel.deepCopy(row);
            break;
          }
        })
      ]
    };
  }
};
